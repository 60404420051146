<template>
<div>
    <div style="padding-top:0px">
        <a-layout>
            <a-layout-content>
                <a-row class="s-layout" style="overflow-x:scroll">
                    <a-col :span="24">
                        <span class="s-o-font1">실시간 검색</span> &nbsp;<span class="s-o-font2">PC결과</span>
                    </a-col>
                    <a-col class="overview" :span="24" style="margin-top:18px;background-color:white;min-width:1150px">
                        <div class="header" style="background-color:white;width:100%;border-bottom:1px solid #dbdbdb;" v-bind:class="[ storetype=='네이버스토어'  ? 'extra' : 'normal' ]">
                            <div style="margin-left:20px;display:flex">
                                <div style="margin-top:20px;">
                                    <a-select default-value="네이버스토어" style="width: 200px" size="large" @change="onSelectStoreType">
                                        <a-select-option value="네이버스토어">
                                            네이버스토어
                                        </a-select-option>
                                        <a-select-option value="쿠팡">
                                            쿠팡
                                        </a-select-option>
                                        <a-select-option value="티몬">
                                            티몬
                                        </a-select-option>
                                        <a-select-option value="카카오쇼핑">
                                            카카오쇼핑
                                        </a-select-option>
                                        <a-select-option value="무신사">
                                            무신사
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <div style="margin-top:20px;">
                                    <a-input @change="onInit()" v-model="storename" placeholder="스토어명" :disabled="loadcount >0" size="large" />
                                </div>
                                <div style="margin-top:20px;" class="s-o-btn1">
                                    <a-input-search @change="onInit()" v-model="keywordname" placeholder="키워드" :disabled="loadcount >0" size="large" @search="invokeSearch">
                                        <a-button slot="enterButton" style="color:white" :disabled="!storename||!keywordname">
                                            검색
                                        </a-button>
                                    </a-input-search>
                                </div>
                                <div style="margin-top:18px;margin-left:5px;">
                                    <a-button style="height:42px" @click="dialogVisible=true">
                                        <i class="fal fa-info-circle">&nbsp;</i> 선택한 스토어명 입력 방법 보기
                                    </a-button>
                                </div>
                                <div style="right:0;margin-top:20px;margin-right:20px;position: absolute;">

                                    <a-select default-value="a" style="width: 200px" size="large" @change="onSelectPageType">
                                        <a-select-option value="a">
                                            20페이지까지 검색
                                        </a-select-option>
                                        <a-select-option value="b" v-if="storetype=='네이버스토어'">
                                            50페이지까지 검색
                                        </a-select-option>
                                        <a-select-option value="c" v-if="storetype=='네이버스토어'">
                                            100페이지까지 검색
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </div>
                            <div style="margin-left:24px;margin-top:10px;margin-bottom:10px;display:flex" v-if="storetype=='네이버스토어'">
                                <div class="scolor-font1">색상</div>
                                <div class="scolor color1" @click="onSelectedColor(16)" v-bind:class="[ color==16  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color2" @click="onSelectedColor(64)" v-bind:class="[ color==64  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color3" @click="onSelectedColor(128)" v-bind:class="[ color==128  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color4" @click="onSelectedColor(256)" v-bind:class="[ color==256  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color5" @click="onSelectedColor(1024)" v-bind:class="[ color==1024  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color6" @click="onSelectedColor(2048)" v-bind:class="[ color==2048  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color7" @click="onSelectedColor(4096)" v-bind:class="[ color==4096  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color8" @click="onSelectedColor(8192)" v-bind:class="[ color==8192  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color9" @click="onSelectedColor(16384)" v-bind:class="[ color==16384  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color10" @click="onSelectedColor(8)" v-bind:class="[ color==8  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color11" @click="onSelectedColor(4)" v-bind:class="[ color==4  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div class="scolor color12" @click="onSelectedColor(1)" v-bind:class="[ color==1  ? 'selected' : '' ]"><i class="far fa-check"></i></div>
                                <div style="margin-left:10px">
                                    <a-dropdown>
                                        <a class="ant-dropdown-link sage-font1" @click="e => e.preventDefault()">
                                            {{ageGenderTitle}}
                                            <a-icon type="down" />
                                        </a>
                                        <div slot="overlay" class="s-drop-main">
                                            <div class="s-drop">
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('','','전체 사용자')">
                                                    전체 사용자
                                                </div>
                                            </div>
                                            <div class="s-drop">
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('M','','남자전체')">
                                                    남자전체
                                                </div>
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('F','','여자전체')">
                                                    여자전체
                                                </div>
                                            </div>
                                            <div class="s-drop">
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('M',10,'남자10대')">
                                                    남자10대
                                                </div>
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('F',10,'여자10대')">
                                                    여자10대
                                                </div>
                                            </div>
                                            <div class="s-drop">
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('M',20,'남자20대')">
                                                    남자20대
                                                </div>
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('F',20,'여자20대')">
                                                    여자20대
                                                </div>
                                            </div>
                                            <div class="s-drop">
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('M',30,'남자30대')">
                                                    남자30대
                                                </div>
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('F',30,'여자30대')">
                                                    여자30대
                                                </div>
                                            </div>
                                            <div class="s-drop">
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('M',40,'남자40대')">
                                                    남자40대
                                                </div>
                                                <div class="s-drop-text1" @click="onSelectedAgeGender('F',40,'여자40대')">
                                                    여자40대
                                                </div>
                                            </div>
                                        </div>
                                    </a-dropdown>
                                </div>

                            </div>
                        </div>

                        <div style="margin:10px;padding-top:0px">
                            <a-table :columns="columns" :data-source="tableData" :pagination="false" :loading="loading">
                                <div slot="title" slot-scope="record" style="display:flex">
                                    <div v-if="record.imgsrc">
                                        <img :src="record.imgsrc" class="pimg">
                                    </div>
                                    <div class="parent">
                                        <div class="s-o-font4">
                                            {{record.mallId}}
                                        </div>
                                        <div class="truncate-overflow s-o-font5">
                                            {{record.title}}
                                        </div>
                                    </div>
                                </div>
                                <div slot="ad" slot-scope="text, record">
                                    <div v-if="text=='T'">
                                        <a-tag color="green">광고</a-tag>
                                    </div>
                                    <div v-if="text=='7'">
                                        <a-tag color="cyan">럭키투데이</a-tag>
                                    </div>
                                    <div v-if="record.rocket">
                                        <img :src="record.rocket" style="height:16px" />
                                    </div>
                                </div>
                                <div slot="productid" slot-scope="record">
                                    <a :href="record.productref" target="_blank">
                                        {{record.productid}}
                                    </a>
                                </div>
                                <div slot="action" slot-scope="text, record">
                                    <div v-if="record.rank < 2150">
                                        <a-button v-if="getFavData(record) == false" :disabled="!license" style="width:90px" type="primary" @click="onHandleReg(record)">등록</a-button>
                                        <a-button v-if="getFavData(record) == true" :disabled="!license" style="width:90px" type="default" @click="onDeleteMaster(record)">등록 취소</a-button>
                                    </div>
                                </div>
                            </a-table>
                        </div>
                    </a-col>
                </a-row>

            </a-layout-content>
        </a-layout>

        <a-modal title="공지사항" v-model="arrive" v-if="msg" :closable="false" :maskClosable="false">
            <div>
                <div v-html="msg" />
            </div>
            <template slot="footer">
                <a-button key="back" @click="arrive=false">취소</a-button>
                <a-button key="submit" type="primary" @click="handleOkNotify">
                    확인 했습니다. 7일동안 그만 보기
                </a-button>
            </template>
        </a-modal>
        <a-modal v-model="dialogVisible" title="쇼핑몰 스토어명 입력 방법" :width="974">
            <template slot="footer">
                <a-button key="back" @click="dialogVisible=false">
                    닫기
                </a-button>
            </template>
            <div>
                <h6>쇼핑몰 내 아래 빨간 표시가 해당 쇼핑몰 스토어명입니다.</h6>
                <img style="width:100%" :src="getRefImages.img" />
            </div>

        </a-modal>

        <a-modal title="노출지원 신청 SR 번호 선택" v-model="visible" @ok="handleOk" :closable="false" :maskClosable="false">
            <a-input placeholder="SR 번호를 입력해주세요" v-model="sr" size="large" style="width:100%" />
        </a-modal>

    </div>
    <nav-footer></nav-footer>
</div>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

String.prototype.trims = function() {
  return this.replace(/^\s+|\s+$/g, "");
};

import SubMenu from "../../layouts/nav/SubMenu";
import NavFooter from "../../layouts/nav/NavFooter";
import firebase from "firebase";
import {
  getCurrentTimeNew,
  getHex,
  replaceAll,
  chkLicense,
  showError,
  showSuccess,
  isMobile
} from "../components/fnc.js";

export default {
  data() {
    return {
      ageGenderTitle: "전체 사용자",
      color: "",
      age: "",
      gender: "",
      license: false,
      dialogVisible: false,
      loading: false,
      current: "",
      arrive: false,
      msg: "",
      previews: ["img/1.png"],
      preview: [
        {
          storetype: "네이버스토어",
          img: "img/naver.png"
        },
        {
          storetype: "쿠팡",
          img: "img/coupang.png"
        },
        {
          storetype: "티몬",
          img: "img/timon.jpg"
        },
        {
          storetype: "카카오쇼핑",
          img: "img/kakao.png"
        },
        {
          storetype: "무신사",
          img: "img/musinsa.png"
        }
      ],
      tableData: [],
      storename: "",
      keywordname: "",
      storetype: "네이버스토어",
      columns: [
        {
          title: "상품",
          dataIndex: "",
          key: "x",
          align: "left",
          scopedSlots: {
            customRender: "title"
          }
        },
        {
          title: "전체순위",
          dataIndex: "rank",
          key: "rank",
          align: "center"
        },
        {
          title: "상태",
          dataIndex: "ad",
          key: "ad",
          align: "center",
          scopedSlots: {
            customRender: "ad"
          }
        },
        {
          title: "현재위치",
          key: "position",
          dataIndex: "position",
          align: "center"
        },
        {
          title: "가격",
          key: "price",
          dataIndex: "price",
          align: "center"
        },
        {
          title: "제품코드",
          key: "productid",
          align: "center",
          scopedSlots: {
            customRender: "productid"
          }
        },
        {
          title: "카테고리",
          key: "category",
          dataIndex: "category",
          align: "center"
        },
        {
          title: "월예상매출",
          key: "output",
          dataIndex: "output",
          align: "center"
        },
        {
          title: "관심제품",
          key: "action",
          align: "center",
          scopedSlots: {
            customRender: "action"
          }
        }
      ],
      favData: [],
      arrive: "F",
      loadcount: 0,
      msg: "",
      count: 0,
      searchtype: "a",
      sr: "",
      srIds: [],
      visible: false
    };
  },
  computed: {
    getRefImages() {
      return this.preview.find(item => item.storetype == this.storetype);
    },
    getCreatedTime() {
      return getCurrentTime(
        new Date(this.$store.state.AppActiveUser.createdAt)
      );
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getLevel() {
      return this.$store.state.AppActiveUser.level;
    },
    getMaster() {
      return this.$store.state.AppActiveUser.isMaster;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();

      return _mobile;
    }
  },
  components: {
    SubMenu,
    NavFooter
  },
  mounted() {
    if (this._mobile) {
      this.$router.push("/pages/notsupport").catch(() => {});
    } else {
      this.license = chkLicense({
        notify: this.$vs.notify
      });
      this.onLoadNotify();
      if (this.getUid) {
        this.onLoadFavoriteList();
        this.onLoadFavoriteList_coupang();
        this.onLoadFavoriteList_tmon();
        this.onLoadFavoriteList_kakao();
        this.onLoadFavoriteList_musinsa();
        this.onLoadReportId();
      }
    }
    fbq("track", "PageView");
  },
  methods: {
    onInit() {
      this.tableData = [];
    },
    onSelectedAgeGender(_gender, _age, _title) {
      this.gender = _gender;
      this.age = _age;
      this.ageGenderTitle = _title;
      this.onInit();
    },
    onSelectedColor(_color) {
      if (this.color == _color && this.color != "") {
        this.color = "";
      } else {
        this.color = _color;
      }
      this.onInit();
    },
    handleOk(e) {
      var self = this;
      this.visible = false;

      this.sr = this.sr.trim();

      var _t = this.srIds.find(item => item.id == this.sr);

      if (_t) {
        showSuccess({
          notify: self.$vs.notify,
          msg: "정상적으로 등록 되었습니다"
        });

        this.visible = false;

        var uref = firebase.database().ref(this.tableId);

        var upData = {
          report: this.refKey,
          md: _t.val.bmgr ? _t.val.bmgr : "-"
        };

        uref.update(upData).then(function() {});

        var uref3 = firebase.database().ref(this.tableIdAuto);

        var upData3 = {
          report: this.refKey,
          md: _t.val.bmgr ? _t.val.bmgr : "-"
        };

        uref3.update(upData).then(function() {});

        var uref2 = firebase.database().ref("rank_apply/" + _t.key);

        var _dump = _t.report ? _t.report.split(",") : [];
        _dump.push(this.refKey);
        var upData2 = {
          masterId: this.tableId,
          autoMasterId: this.tableIdAuto,
          report_bk: _dump.toString(),
          report: this.refKey
        };

        uref2.update(upData2).then(function() {});
      } else {
        showError({
          notify: self.$vs.notify,
          msg:
            "sr 번호를 찾지 못했습니다. SR 번호는 최근 7일 전 데이타만 가능합니다"
        });
      }
    },
    onLoadReportId() {
      var _s = new Date().addDays(-60).getTime();
      var _e = new Date().getTime();
      var self = this;
      var sref = firebase
        .database()
        .ref("rank_apply/")
        .orderByChild("createdAt")
        .startAt(_s)
        .endAt(_e);

      sref.once("value", function(sdata) {
        sdata.forEach(function(sdatas) {
          var _t = {
            key: sdatas.key,
            id: sdatas.val().order,
            report: sdatas.val().report ? sdatas.val().report : "",
            val: sdatas.val()
          };
          self.srIds.push(_t);
        });
      });
    },
    onHandleReg(item) {
      if (this.license) {
        var _tablename = "execution_master/";
        if (this.storetype == "쿠팡") {
          _tablename = "execution_master_coupang/";
        } else if (this.storetype == "티몬") {
          _tablename = "execution_master_tmon/";
        } else if (this.storetype == "무신사") {
          _tablename = "execution_master_musinsa/";
        } else if (this.storetype == "와디즈") {
          _tablename = "execution_master_wadiz/";
        } else if (this.storetype == "카카오쇼핑") {
          _tablename = "execution_master_kakao/";
        } else {
          _tablename = "execution_master/";
        }

        var self = this;

        var d2 = new Date();
        var d = getCurrentTimeNew();

        var _color = this.color != "" ? "&color=" + this.color : "";
        var _age = this.age != "" ? "&age=" + this.age : "";
        var _gender = this.gender != "" ? "&gender=" + this.gender : "";
        var _extra = _color + _age + _gender;

        // this.keywordname = this.keywordname.toUpperCase().trim();

        var _id = getHex(
          this.getUid + item.productid + this.keywordname + item.ad
        );

        //Below key belong to server auto crwaling

        this.tableId = _tablename + _id + "/";

        this.refKey = this.getUid + this.storename + item.productid + item.ad;
        var uref = firebase.database().ref(_tablename + _id);

        var upData = {
          uid: this.getUid,
          keyword: this.keywordname,
          store: this.storename,
          eventdttm: d,
          eventdttmAt: d2.getTime(),
          productId: item.productid,
          productTitle: item.title,
          ahref: item.productref,
          remain: 0,
          expire: 0,
          mallId: item.mallId,
          ad: item.ad,
          extra: _extra,
          color: this.color,
          age: this.age,
          gender: this.gender
        };

        uref
          .update(upData)
          .then(function() {
            self.onMarkStar("T", _id);

            showSuccess({
              notify: self.$vs.notify,
              msg: "정상적으로 등록 되었습니다"
            });

            self.count++;
            self.$store.dispatch("updateUserInfo", {
              currCount: self.count
            });
          })
          .catch(function(error) {
            alert("Data could not be saved." + error);
          });
        if (this.getMaster == "T") {
          this.visible = true;
          this.sr = "";
        }
      }
    },
    onMarkStar(_val, key) {
      var _tablename = "execution_master/";
      var _autotablename = "auto_execution_master/";
      if (this.storetype == "쿠팡") {
        _tablename = "execution_master_coupang/";
        _autotablename = "auto_execution_master_coupang/";
      } else if (this.storetype == "티몬") {
        _tablename = "execution_master_tmon/";
        _autotablename = "auto_execution_master_tmon/";
      } else if (this.storetype == "무신사") {
        _tablename = "execution_master_musinsa/";
        _autotablename = "auto_execution_master_musinsa/";
      } else if (this.storetype == "와디즈") {
        _tablename = "execution_master_wadiz/";
        _autotablename = "auto_execution_master_wadiz/";
      } else if (this.storetype == "카카오쇼핑") {
        _tablename = "execution_master_kakao/";
        _autotablename = "auto_execution_master_kakao/";
      } else {
        _tablename = "execution_master/";
        _autotablename = "auto_execution_master/";
      }

      var self = this;
      var sref = firebase.database().ref(_tablename + key + "/");

      this.tableIdAuto = _autotablename + key + "/";
      sref.once("value", function(sdata) {
        var uref = firebase.database().ref(_autotablename + key + "/");
        var d2 = new Date();
        var d = getCurrentTimeNew();
        var _mallId = "";
        if (sdata.child("mallId").exists()) {
          _mallId = sdata.val().mallId;
        } else {
          _mallId = sdata.val().store;
        }
        var upData = {
          autoTrack: _val,
          store: sdata.val().store,
          remain: sdata.val().remain,
          expire: sdata.val().expire,
          keyword: sdata.val().keyword,
          productId: sdata.val().productId,
          title: sdata.val().productTitle,
          uid: sdata.val().uid,
          createdAt: d2.getTime(),
          mallId: _mallId,
          eventdttm: d,
          reverseCreatedAt: -d2.getTime(),
          ad: sdata.val().ad,
          mark: "1",
          count: 0,
          extra: sdata.val().extra ? sdata.val().extra : "",
          color: sdata.val().color ? sdata.val().color : "",
          age: sdata.val().age ? sdata.val().age : "",
          gender: sdata.val().gender ? sdata.val().gender : ""
        };
        uref.update(upData);

        if (_val == "T") {
          self.favData.push({
            sysid: key,
            ad: sdata.val().ad,
            productId: sdata.val().productId,
            keyword: sdata.val().keyword
          });
        }
      });
      var uref2 = firebase.database().ref(_tablename + key + "/");

      var upData2 = {
        autoTrack: _val
      };

      uref2.update(upData2);
    },
    onDeleteMaster(item) {
      var self = this;
      if (item.sysid) {
        //Naver
        var sref = firebase
          .database()
          .ref("execution_master/" + item.sysid + "/");
        sref
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        var sref2 = firebase
          .database()
          .ref("auto_execution_master/" + item.sysid + "/");
        sref2
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        //coupang

        var sref3 = firebase
          .database()
          .ref("execution_master_coupang/" + item.sysid + "/");
        sref3
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        var sref4 = firebase
          .database()
          .ref("auto_execution_master_coupang/" + item.sysid + "/");
        sref4
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        //tmon
        var sref5 = firebase
          .database()
          .ref("execution_master_tmon/" + item.sysid + "/");
        sref5
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        var sref6 = firebase
          .database()
          .ref("auto_execution_master_tmon/" + item.sysid + "/");
        sref6
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        //musinsa
        var sref7 = firebase
          .database()
          .ref("execution_master_musinsa/" + item.sysid + "/");
        sref7
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        var sref8 = firebase
          .database()
          .ref("auto_execution_master_musinsa/" + item.sysid + "/");
        sref8
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        //wadiz
        var sref9 = firebase
          .database()
          .ref("execution_master_wadiz/" + item.sysid + "/");
        sref9
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        var sref10 = firebase
          .database()
          .ref("auto_execution_master_wadiz/" + item.sysid + "/");
        sref10
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        //wadiz
        var sref11 = firebase
          .database()
          .ref("execution_master_kakao/" + item.sysid + "/");
        sref11
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        var sref12 = firebase
          .database()
          .ref("auto_execution_master_kakao/" + item.sysid + "/");
        sref12
          .remove()
          .then(function() {
            //
          })
          .catch(function(error) {
            console.log("Remove failed: " + error.message);
          });

        this.favData = this.favData.filter(sitem => {
          if (sitem.sysid == item.sysid) {
            //skip
          } else {
            return sitem;
          }
        });

        showSuccess({
          notify: this.$vs.notify,
          msg: "정상적으로 삭제 되었습니다"
        });

        this.count--;
        this.$store.dispatch("updateUserInfo", {
          currCount: self.count
        });
      }
    },
    getFavData(row) {
      return this.favData.find(item => item.sysid == row.sysid) ? true : false;
    },
    onSearch(type, _page) {
      var __keyword = encodeURIComponent(this.keywordname);
      var self = this;
      // console.log(type);
      if (type == "네이버스토어") {
        var _color = this.color != "" ? "&color=" + this.color : "";
        var _age = this.age != "" ? "&age=" + this.age : "";
        var _gender = this.gender != "" ? "&gender=" + this.gender : "";

        var url = {
          url:
            "https://search.shopping.naver.com/search/all.nhn?origQuery=" +
            __keyword +
            "&pagingIndex=" +
            _page +
            "&pagingSize=40&viewType=list&sort=rel&frm=NVSHPAG&query=" +
            __keyword +
            _color +
            _age +
            _gender
        };
        this.loadcount++;

        this.$http
          .post(
            "https://asia-northeast1-storelink-fnc.cloudfunctions.net/getHTMLDataByURL",
            url
          )
          .then(function(r) {
            var _s1 = r.data.indexOf('<script id="__NEXT_DATA__"');
            var _e1 = r.data.indexOf("script>", _s1);
            var _test = r.data.substring(_s1, _e1 + 7);

            var _test2 = JSON.parse($($.parseHTML(_test))[0].innerHTML);

            var response = _test2.props.pageProps.initialState.products.list;

            var _idx = 0;

            Object.keys(response).forEach(function(k) {
              var _v = response[k];
              var item = _v.item;

              _idx++;
              var __item = {};

              __item["ad"] = item.adId ? "T" : "F";
              __item["mallId"] = item.mallName
                ? item.mallName
                : item.maker ? item.maker : "X";

              var __store = replaceAll(self.storename, " ", "");
              __store = __store.toUpperCase();

              var _low_ = item.lowMallList ? item.lowMallList : [];

              if (_low_.length > 0) {
                _low_.forEach(sitem => {
                  var _sname = sitem.name.toUpperCase();
                  _sname = replaceAll(_sname, " ", "");
                  if (_sname.indexOf(__store) >= 0) {
                    __item["mallId"] = sitem.name;
                  }
                });
              }

              if (item.dealName == "럭키투데이") {
                __item["ad"] = "7";
                __item["mallId"] = item.mallInfoCache.name.toUpperCase();
              } else {
                __item["mallId"] = __item["mallId"].toUpperCase();
              }
              __item["mallId"] = replaceAll(__item["mallId"], " ", "");
              if (__item["mallId"].indexOf(__store) >= 0) {
                __item["imgsrc"] = item.imageUrl
                  ? item.imageUrl
                  : item.productImgUrl;
                __item["price"] = parseInt(item.price).toLocaleString() + "원";
                __item["category"] =
                  item.category1Name +
                  " > " +
                  item.category2Name +
                  " > " +
                  item.category3Name;
                __item["title"] = item.productTitle
                  ? item.productTitle
                  : item.productName;
                __item["productref"] = item.adcrUrl ? item.adcrUrl : item.crUrl;
                __item["productid"] = item.mallProductId
                  ? item.mallProductId
                  : item.mallPid ? item.mallPid : item.id;
                __item["rank"] = _idx + 40 * (_page - 1);
                __item["position"] =
                  _idx.toString() + "순위 (" + _page.toString() + "페이지)";
                __item["sysid"] = getHex(
                  self.getUid +
                    __item["productid"] +
                    self.keywordname +
                    __item["ad"]
                );

                var _output = Math.round(
                  parseInt(item.purchaseCnt) * parseInt(item.price) / 3
                );
                __item["output"] =
                  _output == 0 ? "-" : _output.toLocaleString() + "원";

                __item["keyword"] = self.keywordname;
                self.tableData.push(__item);
              }
            });

            self.loadcount--;

            self.doSortTable();
          })
          .catch(function(error) {
            self.loadcount--;
            self.doSortTable();
          });

        var _max =
          self.searchtype == "a" ? 21 : self.searchtype == "b" ? 51 : 101;
        if (_page < _max) {
          self.onSearch(type, _page + 1);
        }
      } else if (type == "쿠팡") {
        var url = {
          url:
            "https://www.coupang.com/np/search?q=" +
            this.keywordname +
            "&channel=user&sorter=scoreDesc&listSize=72&filter=&isPriceRange=false&brand=&offerCondition=&page=" +
            _page
        };
        this.loadcount++;

        this.$http
          .post(
            "https://us-central1-storelink2.cloudfunctions.net/callurl7",
            url
          )
          .then(function(r) {
            if (
              r.data.indexOf("<title>Access Denied</title>") >= 0 ||
              r.data.indexOf(
                "Request failed, with status codes original_status"
              ) >= 0
            ) {
              self.loadcount--;
            } else {
              var basis = $($.parseHTML(r.data)).find(".search-content");

              var response = $(basis).find(".search-product");

              if (_page === 1) {
                var _k = new Map();

                var rkeyword = $($.parseHTML(r.data))
                  .find(".search-related-keyword")
                  .find("dd")
                  .find("a");
                self.units = [];

                var _find = false;

                rkeyword.each(function(idx, item) {
                  if ($(item).text()) {
                    _k.set($(item).text(), $(item).text());
                  }
                });

                _k.forEach(item => {
                  var _a = {
                    name: item
                  };
                  self.units.push(_a);
                });
              }

              response.each(function(idx, item) {
                var _idx = parseInt(idx) + 1;
                var __item = {};

                __item["title"] = $(item)
                  .find("a")
                  .find("div.name")
                  .text();

                __item["title"] = __item["title"].toUpperCase();
                __item["mallId"] = $(item).attr("id");
                var __store = replaceAll(self.storename, " ", "");
                __store = __store.toUpperCase();

                if (__item["title"].indexOf(__store) >= 0) {
                  var _ad = $(item)
                    .find("a")
                    .find("span.ad-badge-text")
                    .text();

                  __item["ad"] = _ad ? "T" : "F";

                  __item["productref"] =
                    "https://www.coupang.com" +
                    $(item)
                      .find("a")
                      .attr("href");

                  var _productId = $(item)
                    .find("a")
                    .attr("data-item-id");

                  __item["productid"] = _productId;

                  var _price = $(item)
                    .find("a")
                    .find("dd")
                    .find("div.price")
                    .first()
                    .find("strong.price-value")
                    .text()
                    .replace(/,/g, "");

                  var _rocket = $(item)
                    .find("a")
                    .find("dd")
                    .find("div.price")
                    .first()
                    .find("span.rocket")
                    .find("img")
                    .attr("src");

                  __item["rocket"] = _rocket ? "https:" + _rocket : "";

                  __item["price"] = parseInt(_price).toLocaleString() + "원";

                  __item["rank"] = _idx + 72 * (_page - 1);

                  if (idx > 36) {
                    __item["position"] =
                      (_idx - 36).toString() +
                      "순위 (" +
                      (_page * 2).toString() +
                      "페이지)";
                  } else {
                    __item["position"] =
                      _idx.toString() +
                      "순위 (" +
                      (_page * 2 - 1).toString() +
                      "페이지)";
                  }

                  __item["sysid"] = getHex(
                    self.getUid +
                      __item["productid"] +
                      self.keywordname +
                      __item["ad"]
                  );

                  __item["keyword"] = self.keywordname;
                  self.tableData.push(__item);
                }
              });

              self.loadcount--;

              self.doSortTable();
            }
          })
          .catch(function(error) {
            self.loadcount--;
            self.doSortTable();
          });
        var _max = self.searchtype == "a" ? 10 : 50;
        if (_page < _max) {
          self.onSearch(type, _page + 1);
        }
      } else if (type == "티몬") {
        var url = {
          url:
            "https://search.tmon.co.kr/api/search/v4/deals?_=1572423587949&keyword=" +
            __keyword +
            "&useTypoCorrection=true&mainDealOnly=true&page=" +
            _page +
            "&sortType=POPULAR&thr=ts"
        };
        this.loadcount++;

        this.$http
          .post(
            "https://us-central1-storelink-fnc.cloudfunctions.net/callurl",
            url
          )
          .then(function(r) {
            var _idx = 0;
            r.data.data.searchDeals.forEach(item => {
              _idx++;

              var __item = {};

              __item["ad"] = item.extraDealInfo.adDeal == true ? "T" : "F";
              __item["mallId"] = item.searchDealResponse.dealInfo.partnerNo;

              if (__item["mallId"] == self.storename) {
                __item["imgsrc"] =
                  item.searchDealResponse.dealInfo.imageInfo.pc3ColImageUrl;

                __item["price"] =
                  item.searchDealResponse.dealInfo.priceInfo.price.toLocaleString() +
                  "원";

                __item["category"] = "-";

                __item["title"] = item.searchDealResponse.dealInfo.titleName;

                __item["productref"] = item.extraDealInfo.detailUrl;

                var _productId = $(item).attr("data-mall-pid");

                __item["productid"] = item.searchDealResponse.searchInfo.id;

                __item["rank"] = _idx + 27 * (_page - 1);

                __item["position"] =
                  _idx.toString() + "순위 (" + _page.toString() + "페이지)";

                __item["sysid"] = getHex(
                  self.getUid +
                    __item["productid"] +
                    self.keywordname +
                    __item["ad"]
                );

                __item["keyword"] = self.keywordname;
                self.tableData.push(__item);
              }
            });
            self.loadcount--;
            self.doSortTable();
          })
          .catch(function(error) {
            self.loadcount--;
            self.doSortTable();
          });

        var _max =
          self.searchtype == "a" ? 21 : self.searchtype == "b" ? 51 : 101;
        if (_page < _max) {
          self.onSearch(type, _page + 1);
        }
      } else if (type == "카카오쇼핑") {
        var __date__ = new Date().getTime();
        var _swap_page = _page - 1;

        var url = {
          url:
            "https://store.kakao.com/a/search/products?page=" +
            _swap_page +
            "&timestamp=&q=" +
            __keyword +
            "&sort=&_=" +
            __date__
        };
        this.loadcount++;

        this.$http
          .post(
            "https://us-central1-storelink-fnc.cloudfunctions.net/callurl",
            url
          )
          .then(function(r) {
            var _idx = 0;
            r.data.data.contents.forEach(item => {
              _idx++;
              // console.log(item);

              var __item = {};

              __item["ad"] = "F";
              __item["mallId"] = item.storeName;

              if (__item["mallId"] == self.storename) {
                __item["imgsrc"] = item.productImage;
                __item["price"] =
                  parseInt(item.originalPrice).toLocaleString() + "원";
                __item["category"] = item.categoryName;
                __item["title"] = item.productName;
                __item["productref"] =
                  "https://store.kakao.com" + item.linkPath;
                __item["productid"] = item.productId;
                __item["rank"] = _idx + 40 * (_page - 1);
                __item["position"] =
                  _idx.toString() + "순위 (" + _page.toString() + "페이지)";

                __item["sysid"] = getHex(
                  self.getUid +
                    __item["productid"] +
                    self.keywordname +
                    __item["ad"]
                );

                __item["keyword"] = self.keywordname;
                self.tableData.push(__item);
              }
            });
            self.loadcount--;
            self.doSortTable();
          })
          .catch(function(error) {
            self.loadcount--;
            self.doSortTable();
          });

        var _max = 21;
        // if (_page < _max) {
        //   self.onSearch(type, _page + 1);
        // }
      } else if (type == "무신사") {
        var __keyword = encodeURIComponent(this.keywordname);
        var url = {
          url:
            "https://search.musinsa.com/search/musinsa/goods?q=" +
            __keyword +
            "&page=" +
            _page
        };
        this.loadcount++;
        // console.log("r", _page);
        this.$http
          .post(
            "https://us-central1-storelink2.cloudfunctions.net/callurl7",
            url
          )
          .then(function(r) {
            var basis = $($.parseHTML(r.data)).find(".list-box");

            var response = basis.find("li.li_box");

            response.each(function(idx, item) {
              var _idx = parseInt(idx) + 1;
              var __item = {};

              // var _item_ = $(item)
              //   .find(".li_inner")
              //   .find(".item_title")
              //   .text();

              __item["mallId"] = $(item)
                .find(".li_inner")
                .find(".item_title")
                .text();

              __item["title"] = $(item)
                .find(".li_inner")
                .find(".list_info")
                .find("a")
                .text()
                .replace(/ /g, "");

              var __mallid = __item["mallId"]
                ? __item["mallId"].toUpperCase()
                : "X";

              var __store = replaceAll(self.storename, " ", "");
              __store = __store.toUpperCase();
              var __store = self.storename;
              if (__mallid.indexOf(__store) >= 0) {
                __item["ad"] = "F";

                __item["productref"] = $(item)
                  .find(".li_inner")
                  .find(".list_info")
                  .find("a")
                  .attr("href");

                __item["productid"] = $(item)
                  .find(".li_inner")
                  .find(".img-block")
                  .attr("data-bh-content-no");

                __item["imgsrc"] = $(item)
                  .find(".li_inner")
                  .find(".img-block")
                  .find("img")
                  .attr("src");

                var _price = $(item)
                  .find(".li_inner")
                  .find(".article_info")
                  .find("p.price")
                  .html();

                if (_price.indexOf("/") > 0) {
                  __item["price"] = _price.substr(_price.indexOf("/") + 5);
                } else {
                  __item["price"] = _price;
                }

                __item["rank"] = idx + 90 * (_page - 1);
                __item["position"] =
                  idx.toString() + "순위 (" + _page.toString() + "페이지)";
                __item["sysid"] = getHex(
                  self.$store.state.uid +
                    __item["productid"] +
                    self.keywordname +
                    __item["ad"]
                );
                __item["category"] = "";
                __item["keyword"] = self.keywordname;
                self.tableData.push(__item);
              }
            });

            self.loadcount--;
            self.doSortTable();
          })
          .catch(function(error) {
            self.loadcount--;
            self.doSortTable();
            // console.log("err", error);
          });
        var _max = 21;
        if (_page < _max) {
          // console.log(_page);
          self.onSearch(type, _page + 1);
        }
      } else if (type == "와디즈") {
        this.loadcount++;
        this.$http
          .post("https://service.wadiz.kr/api/v1/searcher/campaign/integrate", {
            keyword: self.keywordname,
            startNum: (_page - 1) * 9,
            limit: 9,
            campaignType: "",
            viewType: [],
            order: "",
            cateOfBsnsArr: [],
            securtDetailTypeArr: [],
            incomeDeductYn: "",
            achievementRateFrom: "",
            achievementRateTo: "",
            investAmountFrom: "",
            investAmountTo: "",
            minFundingAmountFrom: "",
            minFundingAmountTo: ""
          })
          .then(function(x) {
            // console.log(x);
            var _data = x.data.datas;
            if (_data) {
              var idx = 0;
              for (var i = 0; i < _data.length; i++) {
                idx = i + 1;

                var item = _data[i];

                if (
                  ((!isNaN(self.storename) &&
                    item.campaignId == self.storename) ||
                    (isNaN(self.storename) &&
                      item.corpName.indexOf(self.storename) >= 0)) &&
                  item.endYn == 0
                ) {
                  item["rank"] = (idx + 9 * (_page - 1)).toString();
                  item["position"] =
                    idx.toString() + "순위 (" + _page.toString() + "페이지)";

                  item["ad"] = "F";
                  item["status"] = "";
                  if (item.achievementRate) {
                    var _price = item.totalBackedAmount
                      ? item.totalBackedAmount
                      : 0;
                    item["status"] =
                      item.achievementRate +
                      "% [" +
                      _price.toLocaleString() +
                      "원]";
                  }
                  item["category"] = item.custValueCodeNm
                    ? item.custValueCodeNm
                    : item.cateOfBsnsName;

                  if (item.periodFinishDate && item["status"] == "") {
                    item["status"] = item.periodFinishDate + " 오픈예정";
                  }
                  // __item["category"] = _category;
                  item["keyword"] = self.keywordname;
                  item["productid"] = item.campaignId;
                  item["mallId"] = item.campaignId;
                  item["productref"] =
                    "https://www.wadiz.kr/web/equity/campaign/" +
                    item.campaignId;

                  item["sysid"] = getHex(
                    self.$store.state.uid +
                      item["productid"] +
                      self.keywordname +
                      item["ad"]
                  );

                  self.tableData.push(item);
                }
              }

              self.loadcount--;
              self.doSortTable();
            } else {
              self.loadcount--;
              self.doSortTable();
            }
          })
          .catch(function(error) {
            self.loadcount--;
            self.doSortTable();
            // console.log("err", error);
          });
        if (_page < 101) {
          // console.log(_page);
          self.onSearch(type, _page + 1);
        }
      }
    },
    onSelectStoreType(value) {
      this.storetype = value;
      this.searchtype = "a";
      this.onInit();
    },
    onSelectPageType(value) {
      this.searchtype = value;
    },
    invokeSearch() {
      //Do not check license when user search keyword
      if (this.loading) {
        showError({
          notify: this.$vs.notify,
          msg: "검색중입니다."
        });
      } else {
        if (this.storename != "" && this.keywordname != "") {
          this.tableData = [];
          this.loading = true;
          this.keywordname = this.keywordname.toUpperCase().trim();
          this.onSearch(this.storetype, 1);
        }
      }
    },
    handleOkNotify() {
      this.$cookies.config("7d");
      this.$cookies.set("notifyflag_c1", "T");
      this.arrive = false;
    },
    doSortTable() {
      var self = this;
      if (self.loadcount <= 0) {
        self.loading = false;
        self.tableData = self.tableData.sort(function(a, b) {
          // desc
          return a.rank < b.rank ? -1 : a.rank > b.rank ? 1 : 0;
        });
      }
    },
    onLoadNotify() {
      var val = this.$cookies.get("notifyflag_c1");
      if (val) {
      } else {
        this.arrive = true;
        var db = firebase.firestore();
        var self = this;
        db
          .collection("notify")
          .where("cid", "==", "1")
          .get()
          .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              self.msg = doc.data().contents;
            });
          });
      }
    },
    onLoadFavoriteList() {
      var self = this;
      var _local = 0;

      var sref2 = firebase.database().ref("execution_master/");
      sref2
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.count++;
              _local++;
            }
          });
        });

      var sref = firebase.database().ref("auto_execution_master/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              var _ad = sdatas.child("ad").exists() ? sdatas.val().ad : "F";

              self.favData.push({
                sysid: sdatas.key,
                ad: _ad,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword,
                mark: sdatas.val().mark
              });
            }
          });

          // console.log("N", _local);

          self.$store.dispatch("updateUserInfo", {
            currCount: self.count
          });

          if (
            !chkLicense({
              notify: self.$vs.notify
            })
          ) {
            return;
          }
        });
    },
    onLoadFavoriteList_kakao() {
      var self = this;
      var _local = 0;

      var sref2 = firebase.database().ref("execution_master_kakao/");
      sref2
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.count++;
              _local++;
            }
          });
        });

      var sref = firebase.database().ref("auto_execution_master_kakao/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              var _ad = sdatas.child("ad").exists() ? sdatas.val().ad : "F";

              self.favData.push({
                sysid: sdatas.key,
                ad: _ad,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword,
                mark: sdatas.val().mark
              });
            }
          });
          // console.log("K", _local);
          self.$store.dispatch("updateUserInfo", {
            currCount: self.count
          });

          if (
            !chkLicense({
              notify: self.$vs.notify
            })
          ) {
            return;
          }
        });
    },
    onLoadFavoriteList_coupang() {
      var self = this;
      var _local = 0;

      var sref2 = firebase.database().ref("execution_master_coupang/");
      sref2
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.count++;
              _local++;
            }
          });
        });

      var sref = firebase.database().ref("auto_execution_master_coupang/");
      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              var _ad = sdatas.child("ad").exists() ? sdatas.val().ad : "F";

              self.favData.push({
                sysid: sdatas.key,
                ad: _ad,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword
              });
            }
          });
          // console.log("C", _local);
          self.$store.dispatch("updateUserInfo", {
            currCount: self.count
          });

          if (
            !chkLicense({
              notify: self.$vs.notify
            })
          ) {
            return;
          }
        });
    },
    onLoadFavoriteList_tmon() {
      var self = this;
      var sref = firebase.database().ref("auto_execution_master_tmon/");
      var _local = 0;

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              var _ad = sdatas.child("ad").exists() ? sdatas.val().ad : "F";

              self.favData.push({
                sysid: sdatas.key,
                ad: _ad,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword
              });
              self.count++;
              _local++;
            }
          });
          // console.log("T", _local);
          self.$store.dispatch("updateUserInfo", {
            currCount: self.count
          });
        });
    },
    onLoadFavoriteList_musinsa() {
      var self = this;
      var sref = firebase.database().ref("auto_execution_master_musinsa/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              var _ad = sdatas.child("ad").exists() ? sdatas.val().ad : "F";

              self.favData.push({
                sysid: sdatas.key,
                ad: _ad,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword
              });
              self.count++;
            }
          });

          self.$store.dispatch("updateUserInfo", {
            currCount: self.count
          });
        });
    },
    onLoadFavoriteList_wadiz() {
      var self = this;
      var sref = firebase.database().ref("auto_execution_master_wadiz/");

      sref
        .orderByChild("uid")
        .equalTo(this.getUid)
        .once("value", function(sdata) {
          sdata.forEach(function(sdatas) {
            if (sdatas.val().autoTrack == "T") {
              self.favData.push({
                sysid: sdatas.key,
                productId: sdatas.val().productId,
                keyword: sdatas.val().keyword
              });
              self.count++;
            }
          });

          self.$store.dispatch("updateUserInfo", {
            currCount: self.count
          });
        });
    }
  }
};
</script>

<style>
.s-o-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.74px;
  color: #000000;
}

.s-o-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.59px;
  color: #0264fb;
}

.s-o-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
}

.s-o-font4 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #000000;
}

.s-o-font5 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #7d7d7d;
}

.s-o-btn1 button {
  background-color: #0264fb !important;
  border-color: #0264fb !important;
}

.parent {
  width: 250px;
  margin-left: 15px;
}

.truncate-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pimg {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}

.ad_rect {
  width: 38px;
  height: 22px;
  border-radius: 3px;
  border: solid 1px #f3a800;
  background-color: rgba(243, 168, 0, 0.05);
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #f3a800;
}

.overview {
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
}

.overview.extra {
  height: 130px;
}

.overview > .header.normal {
  height: 80px;
}

.scolor {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;
}

/* border: 1px solid #e8e8e8; */
.scolor-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

.sage-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  color: black;
}

.scolor > i {
  padding-left: 6px;
  padding-top: 6px;
  font-size: 12px;
}

.color1 {
  background-color: #ed1919;
}

.color1 > i {
  color: #ed1919;
}

.color1.selected > i {
  color: white;
}

.color2 {
  background-color: #f5aa24;
}

.color2 > i {
  color: #f5aa24;
}

.color2.selected > i {
  color: white;
}

.color3 {
  background-color: #f5d422;
}

.color3 > i {
  color: #f5d422;
}

.color3.selected > i {
  color: white;
}

.color4 {
  background-color: #f1f223;
}

.color4 > i {
  color: #f1f223;
}

.color4.selected > i {
  color: white;
}

.color5 {
  background-color: #a5dd0e;
}

.color5 > i {
  color: #a5dd0e;
}

.color5.selected > i {
  color: white;
}

.color6 {
  background-color: #35b401;
}

.color6 > i {
  color: #35b401;
}

.color6.selected > i {
  color: white;
}

.color7 {
  background-color: #98d0e8;
}

.color7 > i {
  color: #98d0e8;
}

.color7.selected > i {
  color: white;
}

.color8 {
  background-color: #3132fd;
}

.color8 > i {
  color: #3132fd;
}

.color8.selected > i {
  color: white;
}

.color9 {
  background-color: #1e2d85;
}

.color9 > i {
  color: #1e2d85;
}

.color9.selected > i {
  color: white;
}

.color10 {
  background-color: #ffffff;
  border: 1px solid #ececec;
}

.color10 > i {
  color: #ffffff;
}

.color10.selected > i {
  color: #b8b8b8;
}

.color11 {
  background-color: #c6c6c6;
}

.color11 > i {
  color: #c6c6c6;
}

.color11.selected > i {
  color: white;
}

.color12 {
  background-color: #1b1b1b;
}

.color12 > i {
  color: #1b1b1b;
}

.color12.selected > i {
  color: white;
}

.s-drop-main {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  width: 200px;
  height: 250px;
  background-color: white;
  padding: 20px;
  padding-right: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 15px;
  border-radius: 5px;
}

.s-drop {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.s-drop-text1 {
  width: 50%;
}
</style>
